<template>
  <moe-page title="反馈列表">
    <!-- 筛选信息 -->
    <moe-inquire @search="feedbackSearch">
      <el-form-item label="用户ID">
        <el-input :value="feedbackParams.userId" @input="(value) => feedbackParams.userId = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入用户ID" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model.trim="feedbackParams.name" placeholder="输入用户名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="问题描述">
          <el-input v-model.trim="feedbackParams.problem" placeholder="输入问题描述" maxlength="100" clearable />
        </el-form-item>
    </moe-inquire>

    <moe-tabs type="border-card" :tabsList="[{ label: '未处理' }, { label: '已处理' }]" @change="handleChange">
      <template #0>
        <moe-table v-if="tabsIndex === 0" ref="feedbackTable" url="/problem/userProblemPage" :params="feedbackParams" :number-show="false" :mode="mode">
          <el-table-column prop="userId" label="用户ID" min-width="80" />
          <el-table-column prop="name" label="用户名称" min-width="80" />
          <el-table-column prop="phone" label="联系方式" min-width="80" />
          <el-table-column prop="problem" label="问题描述" min-width="130" :show-overflow-tooltip="true" />
          <el-table-column label="图片" min-width="130">
            <template slot-scope="{ row }">
              <div class="df jcc" v-if="row.coverUrl">
                <div class="mr-5" v-for="(item, index) in row.coverUrl.split(',')" :key="index">
                  <moe-image :src="item" width="50px" height="50px"></moe-image>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="150">
            <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
          </el-table-column>
          <el-table-column label="问题来源" min-width="130">
            <template slot-scope="{ row }">
              {{ row.assort || row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="130">
            <template slot-scope="{ row }">
              {{ row.replied === 0 ? '未处理' : '已回复' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="130">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" icon="el-icon-document" @click="req_queryUserProblem(row.id)">查看</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </template>
      <template #1>
        <moe-table v-if="tabsIndex === 1" ref="feedbackTable" url="/problem/userProblemPage" :params="feedbackParams" :number-show="false" :mode="mode">
          <el-table-column prop="userId" label="用户ID" min-width="80" />
          <el-table-column prop="name" label="用户名称" min-width="80" />
          <el-table-column prop="phone" label="联系方式" min-width="80" />
          <el-table-column prop="problem" label="问题描述" min-width="130" :show-overflow-tooltip="true" />
          <el-table-column label="图片" min-width="130">
            <template slot-scope="{ row }">
              <div class="df jcc" v-if="row.coverUrl">
                <div class="mr-5" v-for="(item, index) in row.coverUrl.split(',')" :key="index">
                  <moe-image :src="item" width="50px" height="50px"></moe-image>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="150">
            <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
          </el-table-column>
          <el-table-column label="问题来源" min-width="130">
            <template slot-scope="{ row }">
              {{ row.assort || row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="130">
            <template slot-scope="{ row }">
              {{ row.replied === 0 ? '未处理' : '已回复' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="130">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" icon="el-icon-document" @click="req_queryUserProblem(row.id)">查看</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </template>
    </moe-tabs>

    <moe-dialog :show="showDialog" title="问题详情" width="50%" @close="handleDialogClose()">
      <div>
        <moe-card class="mb-20">
          <template slot="header">
            基本信息
          </template>
          <moe-describe-list title="" :col="2">
            <moe-describe-item label="用户ID"> {{ feedbackReplyParams.userId }} </moe-describe-item>
            <moe-describe-item label="联系方式"> {{ feedbackReplyParams.phone || '—' }} </moe-describe-item>
            <moe-describe-item label="用户名称"> {{ feedbackReplyParams.name }} </moe-describe-item>
            <moe-describe-item label="问题来源"> {{ feedbackReplyParams.assort || feedbackReplyParams.deviceName }} </moe-describe-item>
          </moe-describe-list>
        </moe-card>
        <moe-card class="mb-20">
          <template slot="header">
            问题描述
          </template>
          <div class="lh-20">{{ feedbackReplyParams.problem }}</div>
        </moe-card>
        <moe-card class="mb-20" v-if="feedbackReplyParams.coverUrl">
          <template slot="header">
            图片
          </template>
          <div class="df">
            <div class="mr-5" v-for="(item, index) in feedbackReplyParams.coverUrl.split(',')" :key="index">
              <moe-image :src="item" width="100px" height="100px"></moe-image>
            </div>
          </div>
        </moe-card>
        <moe-card class="mb-20">
          <template slot="header">
            回复
          </template>
          <moe-form
            ref="feedbackReplyForm"
            class="default-form"
            :showback="false"
            :showTool="false"
            :model="feedbackReplyParams"
            :rules="rules">
            <el-form-item prop="answer" v-if="showDialog">
              <moe-tinymce v-if="feedbackReplyParams.replied === 0" v-model="feedbackReplyParams.answer"></moe-tinymce>
              <div v-else-if="feedbackReplyParams.replied === 1" v-html="feedbackReplyParams.answer"></div>
            </el-form-item>
          </moe-form>
        </moe-card>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
        <el-button v-if="feedbackReplyParams.replied === 0" type="primary" @click="handleAnswer()">回复</el-button>
      </template>

      <moe-dialog :show="showDialog2" title="回复确认" width="50%" @close="handleDialogClose2()">
        <div class="font-20 fw mb-10">请确认回复内容是否无误</div>
        <div>
          回复内容:
          <div v-html="feedbackReplyParams.answer"></div>
        </div>
        <template slot="footer">
          <el-button @click="handleDialogClose2()">取消</el-button>
          <el-button v-if="feedbackReplyParams.replied === 0" type="primary" @click="req_answerUserProblem()">确定</el-button>
        </template>
      </moe-dialog>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FqaManageFeedbackList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      tabsIndex: 0,
      feedbackParams: {
        pageNum: 1,
        pageSize: 10,
        replied: 0,
        userId: '',
        name: '',
        problem: '',
      },
      showDialog: false,
      feedbackReplyLoading: false,
      feedbackReplyParams: {
        userId: '',   //用户id
        phone: '',    //联系方式
        name: '',     //用户名称

        problem: '',  //问题
        replied: '',  //回复状态 0：未回复 1：已回复
        coverUrl: '',
        answer: '',   //答复
      },
      rules: {
        answer: verify.isEmpty('请输入回复'),
      },
      mode: false,
      showDialog2: false,
    }
  },
  methods: {
    feedbackSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.feedbackParams = {
          pageNum: 1,
          pageSize: 10,
          replied: this.tabsIndex || 0,
          userId: '',
          name: '',
          problem: '',
        };
      }

      this.$refs.feedbackTable.searchData();
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.feedbackParams.pageNum = 1;
      this.feedbackParams.pageSize = 10;
      this.feedbackParams.replied = index;
    },
    handleDialogClose() {
      this.showDialog = false;
      this.$refs['feedbackReplyForm'].resetFields();
    },
    /** 弹框点击确认回复 */
    handleAnswer() {
      this.$refs['feedbackReplyForm'].validate(() => {
        this.showDialog2 = true;
      })
    },
    /** */
    handleDialogClose2() {
      this.showDialog2 = false;
    },
    /** 答复用户反馈问题 */
    req_answerUserProblem() {
      this.$moe_api.FQA_API.answerUserProblem({
        id: this.feedbackReplyParams.id,
        answer: this.feedbackReplyParams.answer
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('回复成功');
          this.showDialog2 = false;
          this.handleDialogClose();
          this.handleChange({ index: this.tabsIndex });
          this.$refs.feedbackTable.searchData();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 分页查询用户反馈问题-详情 */
    req_queryUserProblem(id) {
      this.$moe_api.FQA_API.queryUserProblem({ id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;
          this.feedbackReplyParams = {
            id: r.id,
            userId: r.userId,
            phone: r.phone,
            name: r.name,
            assort: r.assort,
            deviceName: r.deviceName,
            problem: r.problem,
            replied: r.replied,
            coverUrl: r.coverUrl,
            answer: r.answer || '',
          };

          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.feedbackSearch(false);
  }
}
</script>

<style lang="scss">

</style>